import React from "react";
import { useParams, useLocation } from "react-router-dom";
import StatusPage from "./StatusPage";

export const StatusContainer = () => {
  let { type } = useParams();
  let query = new URLSearchParams(useLocation().search);
  let reason = query ? query.get("reason") : "";

  return (
    <StatusPage
      type={type ?? "error"}
      reason={reason ?? "Something went wrong!"}
    />
  );
};  