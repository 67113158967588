const routeConstant = {
  landingPage: "landing-page",
  login: "login",
  signup: "signup",
  dashboard: "/",
  swipeFiles: "/swipe-ad",
  discovery: "/explore-lounge",
  team: "/team",
  help: "/help",
  error: "*",
  settings: "settings",
  starred: "/starred",
  forgotPassword: "forgot-password",
  resetPassword: "reset-password",
  notification: "/notification",
  termsConditions: "terms-conditions",
  subscription: "subscription",
  paymentHistory: "/paymentHistory",
  adDetails: "/adDetails",
  myFolders: "/myFolders",
  sharedFolders: "/sharedFolders",
  paymentProcessing: '/payment/processing',
  paymentStatus: '/payment/status',
  paymentPreview: '/payment/preview',
  paymentCheckout: '/payment/checkout',
  redirect: '/payment/redirect',
};

export default routeConstant;
