import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";
import PaymentHeader from "./PaymentHeader";
import { CustomRowCell, CustomTableCell, CustomTableRow } from "./style";
import { PaymentTickIcon } from "../../assets/Icons";
import { CustomButton } from "../../globalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentPlanSelector } from "../../store/account/selector";
import { useEffect, useState } from "react";
import { AccountSagaActions } from "../../store/account/sagas";
import routeConstant from "../../utils/routeConstant";
import { paymentType } from "../../utils/constants";
import PaymentTypeModal from "./PaymentTypeModal";

const PreviewPage = () => {
  const { subscriptionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(AccountSagaActions.getCurrentPlan({ id: subscriptionId }));
  }, [subscriptionId]);

  const currentPlan = useSelector(currentPlanSelector);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFF",
        gap: "25px",
      }}
    >
      <PaymentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "32px",
            color: "#414042",
            textAlign: "center",
          }}
        >
          Cart
        </Typography>
        <Box display="flex" flexDirection="column" gap="30px">
          {!currentPlan?.name ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress size="50px" />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <CustomTableRow>
                  <CustomRowCell style={{ borderRadius: "15px 0px 0px 0px" }}>
                    Subscription Plan
                  </CustomRowCell>
                  <CustomRowCell>Description</CustomRowCell>
                  <CustomRowCell>Includes</CustomRowCell>
                  <CustomRowCell
                    price
                    style={{
                      alignItems: "end !important",
                      borderRadius: "0px 15px 0px 0px",
                    }}
                  >
                    Price
                  </CustomRowCell>
                </CustomTableRow>
              </TableHead>
              <TableBody>
                <CustomTableRow>
                  <CustomTableCell style={{ borderRadius: "0px 0px 0px 15px" }}>
                    {currentPlan?.name}
                  </CustomTableCell>
                  <CustomTableCell>{currentPlan?.description}</CustomTableCell>
                  <CustomTableCell>
                    {currentPlan?.featureList?.map((perk: string) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <PaymentTickIcon />
                        <Box>{perk}</Box>
                      </Box>
                    ))}
                  </CustomTableCell>
                  <CustomTableCell
                    style={{
                      borderRadius: "0px 0px 15px 0px",
                      textAlign: "right",
                    }}
                  >
                    ${currentPlan?.amount}
                  </CustomTableCell>
                </CustomTableRow>
              </TableBody>
            </Table>
          )}

          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            gap="20px"
          >
            <Typography
              sx={{ fontSize: "20px", fontWeight: 600, color: "#414042" }}
            >
              Total : ${currentPlan?.amount}
            </Typography>
            <CustomButton
              style={{
                fontSize: "16px",
                color: "white",
                borderRadius: "25px",
                background:
                  "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                padding: "14px 30px",
                height: "38px",
              }}
              onClick={() =>
                setIsOpen(true)
              }
            >
              Continue to Checkout
            </CustomButton>
          </Box>
        </Box>
      </Box>

      {isOpen && (
        <PaymentTypeModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          subscriptionId={subscriptionId}
        />
      )}
    </Box>
  );
};

export default PreviewPage;
