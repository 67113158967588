import React from "react";
import { Box, DialogTitle, IconButton, Stack } from "@mui/material";
import routeConstant from "../../utils/routeConstant";
import { StyledDivider } from "../../globalStyle";
import { useNavigate } from "react-router-dom";
import { paymentType } from "../../utils/constants";
import { CloseIcon } from "../../assets/Icons";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalTitle,
  StyledDialog,
} from "../modals/style";

const PaymentTypeModal = ({ subscriptionId, isOpen, setIsOpen }: any) => {
  const navigate = useNavigate();

  const paymentMethods = [
    { name: "Drop-in", type: paymentType.dropin },
    { name: "Card", type: paymentType.card },
    { name: "iDEAL", type: paymentType.ideal },
    { name: "giropay", type: paymentType.giropay },
    { name: "Dotpay", type: paymentType.dotpay },
    { name: "EPS", type: paymentType.eps },
    { name: "Sofort", type: paymentType.directEbanking },
    { name: "Bancontact card", type: paymentType.bcmc },
    { name: "PaySafe card", type: paymentType.paysafecard },
    { name: "Klarna - Pay now", type: paymentType.klarna_paynow },
    { name: "Klarna - Pay later", type: paymentType.klarna },
    { name: "Klarna - Slice it", type: paymentType.klarna_account },
  ];

  return (
    <StyledDialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
      <DialogTitle
        sx={{
          padding: "1.875rem 1.875rem 1.25rem 1.875rem",
        }}
      >
        <Stack direction="column" gap="0.75rem" alignItems="start">
          <ModalTitle>Recommended Payment Options</ModalTitle>
          <FontGrayNormal>
            Please select payment method to proceed further.
          </FontGrayNormal>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <Box
        sx={{
          paddingY: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box paddingX="40px">
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "30px",
            }}
          >
            {paymentMethods.map((method) => (
              <IconButton
                key={method.type}
                style={{
                  fontSize: "16px",
                  color: "white",
                  borderRadius: "30px",
                  background:
                    "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                  padding: "14px 40px",
                  height: "38px",
                }}
                onClick={() => {
                  navigate(
                    `${routeConstant?.paymentCheckout}/${method.type}/${subscriptionId}`
                  );
                }}
              >
                <p>{method.name}</p>
              </IconButton>
            ))}
          </ul>
        </Box>
      </Box>
    </StyledDialog>
  );
};

export default PaymentTypeModal;
