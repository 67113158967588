import axiosClient from "../apiClient";

export const getCurrentPlanDetails = async ({ id }: { id: string }) => {
  try {
    const response = await axiosClient.get(`/plan/details/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSession = async (type: string, subscriptionId: string) => {
  try {
    const response = await axiosClient.post(`/payment/sessions?type=${type}`, {
      subscriptionId: subscriptionId,
      countryCode: "NL",
      currency: "EUR",
    });
    return response;
  } catch (error) {
    return error;
  }
};
