import { Box } from "@mui/material";
import React from "react";
import { PaymentHeaderIcon } from "../../assets/Icons";

const PaymentHeader = () => {
  return (
    <Box
      sx={{
        borderBottom: "1px solid #EAECEE",
        padding: "30px 0px",
        backgroundColor: "#F9F9FA",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <PaymentHeaderIcon />
    </Box>
  );
};

export default PaymentHeader;
