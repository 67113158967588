import { Box, Typography } from "@mui/material";
import { ProcessingPaymentIcon, SuccessPaymentIcon } from "../../assets/Icons"; // Assuming you have these icons
import PaymentHeader from "./PaymentHeader";
import { CustomButton } from "../../globalStyle";
import { Link } from "react-router-dom"; // Using Link for routing
import routeConstant from "../../utils/routeConstant";

type StatusPagePropsType = {
  type: string;
  reason: string;
};

const StatusPage = ({ type, reason }: StatusPagePropsType) => {
  let msg, img, route;

  switch (type) {
    case "pending":
      msg = (
        <span>Your order has been received! Payment completion pending.</span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.dashboard;
      break;
    case "failed":
      msg = (
        <span>
          The payment was refused. Please try a different payment method or
          card.
        </span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.subscription;
      break;
    case "error":
      msg = (
        <span>
          Error! Reason: {reason || "Internal error"}, refer to&nbsp;
          {/* TODO: Remove before going live */}
          <a
            href="https://docs.adyen.com/development-resources/response-handling"
            target="_blank"
            rel="noopener noreferrer"
          >
            Response handling.
          </a>
        </span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.subscription;
      break;
    default:
      msg = <span>Your order has been successfully placed.</span>;
      img = <SuccessPaymentIcon />;
      route = routeConstant.dashboard;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        gap: "170px",
        backgroundColor: "#FFF",
      }}
    >
      <PaymentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {img}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "39px",
              color: "#414042",
              textAlign: "center",
            }}
          >
            {type === "success"
              ? "Payment done successfully!"
              : "Payment failed!"}
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "30px",
              textAlign: "center",
              color: "#58595B",
            }}
          >
            {msg}
          </Typography>
          <Link to={route}>
            <CustomButton
              style={{
                marginTop: "40px",
                fontSize: "16px",
                color: "white",
                borderRadius: "25px",
                background:
                  "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                padding: "10px 30px",
                height: "38px",
              }}
            >
              {type === "success" || type === "pending"
                ? "Go to Home Page"
                : "Go back to Subscriptions"}
            </CustomButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusPage;
