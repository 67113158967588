import routeConstant from "./routeConstant";

/**
 * Converts a result code from the Adyen API to a url which can be used to redirect.
 * @param {string} resultCode Result code obtained from Adyen API.
 * @returns A url to redirect to.
 */
export const getRedirectUrl = (resultCode: string) => {
  switch (resultCode) {
    case "Received":
    case "Authorised":
      return `${routeConstant?.paymentStatus}/success`;
    case "Pending":
      return `${routeConstant?.paymentStatus}/pending`;
    case "Refused":
      return `${routeConstant?.paymentStatus}/failed`;
    default:
      return `${routeConstant?.paymentStatus}/error`;
  }
};
