import { Box, Typography } from "@mui/material";
import { ProcessingPaymentIcon } from "../../assets/Icons";
import PaymentHeader from "./PaymentHeader";

const ProcessingPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        gap: "170px",
        backgroundColor: "#FFF",
      }}
    >
      <PaymentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        <ProcessingPaymentIcon />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "48%",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "39px",
              color: "#414042",
              textAlign: "center",
            }}
          >
            Your Payment is under Process!
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "30px",
              textAlign: "center",
              color: "#58595B",
            }}
          >
            Please do not close the window as the payment is under process, Even
            if the User clicks the back button and the payment is completed from
            the payment gateway, it cannot be reverted.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ProcessingPage;
