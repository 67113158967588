import { IconButton, TableCell, TableRow } from "@mui/material";
import styled from "styled-components";

export const CustomRowCell = styled(TableCell)<{ price?: boolean }>`
  display: flex !important;
  width: 300px;
  height: 20px;
  padding: 6px 15px;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: ${(props) => (props.price ? "end" : "flex-start")};
  font-size: 16px !important;
  font-weight: 600 !important;
  gap: 6px;
  border: 1px solid #eaecee;
`;

export const CustomTableRow = styled(TableRow)`
  display: flex !important;
  align-items: center;
  align-self: stretch;
`;

export const CustomTableCell = styled(TableCell)`
  display: flex;
  width: 300px;
  height: 150px;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border: 1px solid #eaecee;
`;