import { call, put, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { createSliceSaga } from "../saga-helper";
import { getSession } from "../../services/PaymentServices";
import { PaymentReducerAction } from "./slice";
import { PayloadAction } from "@reduxjs/toolkit";
import routeConstant from "../../utils/routeConstant";

const saga = createSliceSaga({
  name: "payment-state",
  caseSagas: {
    *initiateCheckout(
      action: PayloadAction<{
        type: string;
        subscriptionId: string;
        BASE_URL: string;
      }>
    ) {
      try {
        const response: AxiosResponse = yield call(
          getSession,
          action?.payload?.type,
          action?.payload?.subscriptionId
        );

        if (response?.status === 200) {
          const updatedData = response.data.map((res: any) => {
            if (res.returnUrl) {
              return {
                ...res,
                returnUrl: `${action.payload.BASE_URL}${routeConstant?.redirect}?${res.returnUrl}`,
              };
            }
            return res;
          });
          yield put(
            PaymentReducerAction.setPaymentSession([updatedData, response.status])
          );
        }
      } catch (error) {
        return error;
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: PaymentSagaActions,
  saga: PaymentSaga,
  name: PaymentSagaName,
} = saga;
