import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  BoardIcon,
  CreateNewIcon,
  DeleteIcon,
  EditIcon,
  EllipsisIcon,
  EllipsisIconLight,
  GridViewIcon,
  OpenFolder,
  ShareIcon,
  StarredIcon,
  VisibleIcon,
} from "../../assets/Icons";
import { FolderListItem, PanelItem } from "../../pageComponents/auth/style";
import useConfig from "../../hooks/useConfig";
import { folderItem, selectFolderBoardItem } from "../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFolderLoadingSelector,
  folderItemSelector,
  folderListsSelector,
  selectedTabIndexSelector,
  selectFolderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import routeConstant from "../../utils/routeConstant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";
import { useTheme } from "styled-components";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { selectedSidebarBoardIdSelector } from "../../store/swipeFile/selector";
import { useEffect, useRef, useState } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { defaultFolderName } from "../../utils/constants";

const SidebarFolder = () => {
  const { drawerOpen } = useConfig();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const folderList = useSelector(folderListsSelector);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedFolder = useSelector(selectFolderSelector);
  const selectedTabIndex = useSelector(selectedTabIndexSelector);
  const selectedSidebarBoardId = useSelector(selectedSidebarBoardIdSelector);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedActionFolder, setSelectedActionFolder] =
    useState<folderItem>();
  const [prevFolderCount, setPrevFolderCount] = useState(
    folderList?.userFolders
  );
  const loading = useSelector(deleteFolderLoadingSelector);

  const handleAnClick = (
    event: React.MouseEvent<HTMLElement>,
    item: folderItem
  ) => {
    setAnchorEl(event.currentTarget);
    dispatch(MetaDataReducerAction.resetSkipLimitValue());
    dispatch(MetaDataReducerAction.setFolderItem(item));
    setSelectedActionFolder(item);
  };

  const selectedFolderItem = useSelector(folderItemSelector);

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(MetaDataReducerAction.setFolderItem(null));
  };

  const handleMenuItemClick = () => {
    dispatch(
      MetaDataReducerAction.setIsRemoveUserModalOpen({
        isOpen: true,
        isUser: false,
      })
    );
    setAnchorEl(null);
  };

  const handleEditFolder = () => {
    dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
    setAnchorEl(null);
  };

  const handleViewFolder = () => {
    dispatch(MetaDataReducerAction.resetSwipefileState());
    dispatch(
      MetaDataReducerAction.setSelectedFolderId(selectedActionFolder?._id)
    );
    dispatch(MetaDataReducerAction.setSelectedFolder(selectedActionFolder));
    dispatch(MetaDataReducerAction.setSelectedTabIndex(0));

    const { _id } = selectedFolderItem;
    navigate(`${routeConstant.myFolders}/${_id}`, {
      state: {
        type: "sidebarFolder",
      },
    });
    handleClose();
  };

  const handleClick = (boardId: string, folderId: string, index: number) => {
    dispatch(SwipeFileReducerAction.setSelectedSidebarBoardId(boardId));
    dispatch(MetaDataReducerAction.setSelectedTabIndex(index));
    dispatch(MetaDataReducerAction.resetSwipefileState());

    dispatch(
      MetaDataSagaActions.fetchFolderListByBoardId({
        selectedFolderId: folderId,
        selectedTabIndex: index,
        boardId: boardId,
      })
    );

    navigate(`${routeConstant.myFolders}/${folderId}`, {
      state: {
        type: "sidebarFolder",
      },
    });
  };

  const closeAnchorElAfterDelete = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(
      MetaDataSagaActions.deleteFolder({
        closeAnchorElAfterDelete: closeAnchorElAfterDelete,
      })
    );
  };

  const constants = {
    myFolders: "My Folders",
  };
  const selectedFolderId = location.pathname.split("/")[2];
  const selectedFolderName = location.pathname.split("/")[1];
  const isSelectedInCurrentFolder =
    selectedFolderName === Object.keys(constants)[0];

  useEffect(() => {
    const currentFolderCount = folderList?.userFolders?.length || 0;

    // Only scroll to bottom if a new folder is added after the initial load
    if (
      currentFolderCount > prevFolderCount &&
      prevFolderCount !== 0 &&
      scrollRef.current
    ) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    // Update the previous folder count
    setPrevFolderCount(currentFolderCount);
  }, [folderList?.userFolders]);

  return (
    <>
      <Divider sx={{ borderColor: "#2f4757" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1.5rem 1.5rem 0.5rem 1.8rem",
        }}
      >
        <span>{constants?.myFolders}</span>
        <div
          onClick={() => {
            dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CreateNewIcon fill="white" />
        </div>
      </div>

      <Box
        ref={scrollRef}
        sx={{
          marginLeft: "1rem",
          marginBottom: "1rem",
          height: "100%",
          maxHeight: {
            xs: "47vh",
            sm: "39vh",
            md: "32vh",
            lg: "27vh",
            xl: "31vh",
          },
          overflowY: "auto",
          overflowX: "hidden",
          "::-webkit-scrollbar": {
            width: "4px",
          },
        }}
      >
        <PanelItem
          drawerOpen={drawerOpen}
          to={`${routeConstant.starred}`}
          style={{
            background:
              location.pathname === routeConstant.starred
                ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                : "transparent",
          }}
        >
          <StarredIcon />
          {drawerOpen && <Typography marginLeft={1}>Starred </Typography>}
        </PanelItem>

        <Box
          sx={{
            marginTop: "-0.5rem",
          }}
        >
          {folderList?.userFolders?.length > 0 &&
            folderList?.userFolders?.map((item: folderItem) => (
              <div>
                <FolderListItem
                  key={item._id}
                  style={{
                    borderRadius: "4px",
                    background:
                      selectedFolder._id === item._id
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "transparent",
                  }}
                >
                  <OpenFolder />
                  {drawerOpen && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          dispatch(
                            MetaDataReducerAction.setSelectedFolder(item)
                          );
                          dispatch(MetaDataReducerAction.setSelectedTabIndex(0));
                          dispatch(MetaDataReducerAction.resetSwipefileState());
                          dispatch(
                            MetaDataSagaActions.fetchFolderListByBoardId({
                              selectedFolderId: item._id,
                              selectedTabIndex: 0,
                              boardId: item?.boards[0]?._id,
                            })
                          );
                        }}
                        style={{
                          marginLeft: 10,
                          cursor:
                            selectedFolderId !== item._id
                              ? "pointer"
                              : "default",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {item.name}
                      </div>

                      {item.name !== defaultFolderName && (
                        <div
                          onClick={(event) => handleAnClick(event, item)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {selectedFolder._id === item._id ? (
                            <EllipsisIconLight />
                          ) : (
                            <EllipsisIcon />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </FolderListItem>

                <Collapse
                  in={selectedFolder?._id === item._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <span
                    style={{
                      marginLeft: "2.5rem",
                      marginBottom: "0.625rem",
                      display: "block",
                      cursor: "pointer",
                    }}
                  >
                    {selectedFolder?.boards?.length > 0
                      ? "Boards"
                      : "No Boards"}
                  </span>
                  {selectedFolder?.boards?.map(
                    (item: selectFolderBoardItem, index: number) => (
                      <>
                        <List key={item?._id} component="div" disablePadding>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              gap: "0.188rem",
                              paddingLeft: "0.625rem",
                              borderLeft: "1px solid #ffff",
                              marginLeft: "2.625rem",
                              paddingTop: "0.625rem",
                              cursor: "pointer",
                              fontWeight: "bolder",
                              color:
                                selectedTabIndex === index
                                  ? theme.colors.lightBlue
                                  : "white",
                            }}
                            onClick={() =>
                              handleClick(item?._id, selectedFolder?._id, index)
                            }
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: "1.5rem",
                                marginLeft: "0.75rem",
                              }}
                            >
                              <BoardIcon fill="white" />
                            </ListItemIcon>
                            <ListItemText
                              primary={item?.name}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          </ListItemButton>
                        </List>
                      </>
                    )
                  )}
                </Collapse>
              </div>
            ))}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            borderRadius: "0.625rem",
          },
          ".MuiList-root": {
            padding: "0",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <MenuItem
          onClick={handleViewFolder}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <VisibleIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            View
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleEditFolder}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <EditIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: theme.colors.black2 }} />
          ) : (
            <DeleteIcon />
          )}
          <Typography
            fontSize="1rem"
            fontWeight="500"
            style={{
              cursor: "pointer",
            }}
          >
            Delete
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleMenuItemClick}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
          }}
        >
          <ShareIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Share
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SidebarFolder;
