import { useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { TeamSettingsFormValuesProp } from "../../types/teamSettingsTypes";
import { useTheme } from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { addTeamMemberValidationSchema } from "../../utils/formValidationSchema";
import { StyledOutlinedInput } from "../../pageComponents/settings/style";
import { CustomButton, StyledDivider } from "../../globalStyle";
import { HeaderSubTitle } from "../../pageComponents/style";
import { CardSubTitle } from "../dashboardSections/style";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import {
  deleteMemberLoaderSelector,
  invitationLoaderSelector,
  teamDetailsSelector,
} from "../../store/metadata/selector";
import { teamMemberDetail } from "../../store/metadata/types";
import { ErrorMessage } from "@hookform/error-message";
import { ToastTypes, notify } from "../../utils/helpers";

const RenderButton = ({
  background,
  color,
  borderColor,
  children,
}: {
  background: string;
  color: string;
  borderColor: string;
  children: React.ReactNode;
}) => {
  return (
    <CustomButton
      sx={{
        padding: "0.625rem 1.25rem",
        borderRadius: "1.25rem",
        background: background,
        border: `1px solid ${borderColor}`,
        color: color,
        pointerEvents: "none",
      }}
    >
      {children}
    </CustomButton>
  );
};

export const TeamSettingsForm = () => {
  // Selectors
  const theme = useTheme();
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<TeamSettingsFormValuesProp>({
    resolver: yupResolver(addTeamMemberValidationSchema),
  });
  const teamMembers = useSelector(teamDetailsSelector);
  const dispatch = useDispatch();
  const loading = useSelector(invitationLoaderSelector);
  const removeloading = useSelector(deleteMemberLoaderSelector);

  // Functions
  const handleResetForm = () => {
    reset();
  };

  const handleInvitation = () => {
    const values = getValues();
    if (Array.isArray(teamMembers) && teamMembers.length > 0) {
      dispatch(
        MetaDataSagaActions.sendInvitation({
          teamId: teamMembers[0]._id,
          email: values.email.toLowerCase(),
          handleResetForm: handleResetForm,
        })
      );
      return;
    }

    notify({
      message: "Can not invite team member as team doesn’t exist!",
      type: ToastTypes.ERROR,
    });
  };

  const removeMember = (email: string) => {
    dispatch(
      MetaDataSagaActions.deleteMember({
        email: email,
        handleResetForm: handleResetForm,
      })
    );
    return;
  }

  return (
    <>
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "500",
          lineHeight: "1.219rem",
          marginBottom: "1.25rem",
          color: `${theme.colors.black2}`,
        }}
      >
        Add Team Member
      </Typography>
      <Stack
        direction={"row"}
        gap={"1.25rem"}
        height="3.375rem"
        marginBottom={"0.938rem"}
      >
        <form
          style={{
            width: "75%",
          }}
        >
          <StyledOutlinedInput
            sx={{
              borderRadius: "1.688rem",
              width: "100%",
            }}
            id="email"
            type="email"
            placeholder="Team Member Email"
            {...register("email")}
          />
        </form>
        <CustomButton
          sx={{
            paddingInline: "1.875rem",
            width: "25%",
            border: `1px solid ${theme.colors.border1}`,
            borderRadius: "1.688rem",
            color: `${theme.colors.gray1}`,
          }}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(handleInvitation)}
        >
          Send Invitation
        </CustomButton>
      </Stack>
      <ErrorMessage
        name="email"
        errors={errors}
        as="p"
        style={{
          color: "red",
          fontSize: "1rem",
          fontWeight: "bolder",
        }}
      />
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "1rem",
          color: `${theme.colors.gray1}`,
          lineHeight: "1.219rem",
          marginTop: "0.5rem",
        }}
      >
        Additional team members pro-rated at $20/mo
      </Typography>
      <Stack marginTop={"2.5rem"}>
        <HeaderSubTitle>Invitations</HeaderSubTitle>
        <StyledDivider sx={{ margin: "1.25rem 0 0.938rem 0" }} />
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            lineHeight: "1.219rem",
            color: `${theme.colors.black2}`,
            marginBottom: "2.5rem",
          }}
        >
          Team Members
        </Typography>
        {Array.isArray(teamMembers) &&
          teamMembers.length > 0 &&
          teamMembers[0]?.members?.map(
            (team: teamMemberDetail, index: number) => {
              return (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    key={index}
                    display={"flex"}
                    alignItems={"center"}
                    gap="0.75rem"
                    width={"49%"}
                    marginBottom={"1.875rem"}
                  >
                    <Avatar
                      src={
                        team?.profileImage
                          ? team.profileImage
                          : "assests/images/user-logo.png"
                      }
                      sx={{
                        width: "2.5rem",
                        height: "2.5rem",
                      }}
                    />
                    <Stack gap={"0.25rem"} marginRight="3.75rem" width="50%">
                      <Typography
                        fontSize="1rem"
                        fontWeight="500"
                        lineHeight="1.219rem"
                        color={theme.colors.black2}
                      >
                        {team.fullName ? team.fullName : "Guest User"}
                      </Typography>
                      <CardSubTitle>{team.email}</CardSubTitle>
                    </Stack>
                    {team.accepted ? (
                      <RenderButton
                        background={theme.colors.mintCream}
                        borderColor={theme.colors.cyan}
                        color={theme.colors.green1}
                        children="Member"
                      />
                    ) : (
                      <RenderButton
                        background={theme.colors.lavenderBlush}
                        borderColor={theme.colors.mistyRose}
                        color={theme.colors.red200}
                        children="Pending"
                      />
                    )}
                  </Box>
                  {teamMembers[0]?.canDelete && !team?.isOwner && (
                    <CustomButton
                      onClick={() => removeMember(team?.email)}
                      disabled={removeloading}
                      startIcon={removeloading && <CircularProgress size={20} color="inherit" />}
                      sx={{
                        padding: "10px 20px",
                        height: "2.25rem",
                        borderRadius: "20px",
                        border: "1px solid #EAECEE",
                        color: "#414042",
                        fontWeight: 500,
                      }}
                    >
                      Remove
                    </CustomButton>
                  )}
                </Box>
              );
            }
          )}
      </Stack>
    </>
  );
};
