import { useEffect, useMemo } from "react";
import {
  Container,
  HeaderSection,
  HeaderSubTitle,
  HeaderTitle,
  ListContainer,
} from "../style";
import AdCard from "../../components/commonComponents/adCard";
import Header from "../../components/header";
import SearchAndFilter from "../../components/searchAndFilter";
import CreateBoardModal from "../../components/modals/createBoardModal";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { useDispatch, useSelector } from "react-redux";
import { FilterOptions, SwipeFileListItems } from "../../store/swipeFile/types";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import SaveAdPopover from "../../components/saveAdPopover";
import {
  Box,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  BoardItem,
  listType,
  selectFolderBoardItem,
} from "../../store/metadata/types";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import AddToBoardModal from "../../components/modals/addToBoardModal";
import AddTagModal from "../../components/modals/addTagModal";
import { useLocation, useParams } from "react-router-dom";
import {
  boardAdsFilterCountSelector,
  boardAdsItemsSelector,
  boardAdsSearchSelector,
  filterOptionSelector,
  isLoadingSelector,
  selectedBrandsSelector,
  selectedPlatformsSelector,
  selectedTabIndexSelector,
  selectedTagsSelector,
  selectFolderSelector,
  sharedFolderListSelector,
} from "../../store/metadata/selector";
import { FolderIconDark } from "../../assets/Icons";
import { theme } from "../../utils/theme";

const MyFolder = () => {
  const dispatch = useDispatch();

  const { folderId } = useParams();
  const { pathname } = useLocation();
  const selectedFolder = useSelector(selectFolderSelector);
  const boardAdsItems = useSelector(boardAdsItemsSelector);
  const userDetails = JSON.parse(localStorage.getItem("User") as string);
  const selectedBrands = useSelector(selectedBrandsSelector);
  const selectedTabIndex = useSelector(selectedTabIndexSelector);
  const selectedPlatforms = useSelector(selectedPlatformsSelector);
  const selectedTags = useSelector(selectedTagsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const filterOption = useSelector(filterOptionSelector);
  const boardAdsFilterCount = useSelector(boardAdsFilterCountSelector);
  const searchValue = useSelector(boardAdsSearchSelector);
  const pathKey = pathname.split("/")[1];
  const folderIdValue = pathname.split("/")[2];
  const sharedFolderItems = useSelector(sharedFolderListSelector);

  const content = {
    tabTitle: isLoading ? "" : selectedFolder?.name,
    tabSubTitle: "",
  };

  const setSwipeFileTabIndex = (tabIndex: number) => {
    dispatch(MetaDataReducerAction.setSelectedTabIndex(tabIndex));
    dispatch(MetaDataReducerAction.resetSwipefileState());
    fetchFolderBoardAds(tabIndex);
  };

  const fetchSortingSwipeFileItems = (
    sortValue: string,
    sortObject: Object
  ) => {
    dispatch(
      MetaDataReducerAction.setSelectedSortOption({ sortValue, sortObject })
    );
    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex,
        type: "filter",
      })
    );
  };

  const fetchfilterSwipeFileItems = (
    selectAll: boolean,
    checked: boolean,
    stateName: keyof FilterOptions,
    list: listType[],
    id?: string
  ) => {
    dispatch(
      MetaDataReducerAction.setSelectedFilterOptions({
        selectAll,
        checked,
        id,
        list,
        stateName,
      })
    );
    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex,
        type: "filter",
      })
    );
  };

  const refetchSwipeFileItem = (adId: string, updatedBoardList: BoardItem) => {
    dispatch(
      SwipeFileReducerAction.setUpdatedBoardIds({ adId, updatedBoardList })
    );
  };

  const handleClearSwipeFileFilter = () => {
    dispatch(MetaDataReducerAction.resetSwipefileState());

    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex: 0,
      })
    );
  };

  const fetchFolderBoardAds = (tabIndex?: number) => {
    const boardId = selectedFolder?.boards[tabIndex ?? selectedTabIndex]?._id;
    dispatch(
      MetaDataSagaActions.fetchFolderListByBoardId({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex: tabIndex ?? selectedTabIndex,
        boardId: boardId,
      })
    );
  };

  useEffect(() => {
    fetchFolderBoardAds();
  }, [folderIdValue]);

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchFavouriteFolderList());
    dispatch(MetaDataSagaActions.fetchFavouriteBrands());
    dispatch(MetaDataSagaActions.fetchBoardList());
    dispatch(MetaDataSagaActions.fetchBrandLists());
    dispatch(MetaDataSagaActions.fetchTagLists());
    return () => {
      dispatch(MetaDataReducerAction.setBrandFilterSearchText(""));
    };
  }, []);

  const fetchMoreSwipeFileItem = () => {
    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex,
      })
    );
  };

  const handleSearchChange = (value: string) => {
    dispatch(MetaDataReducerAction.setBoardAdsSearchValue(value));
    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: folderId ?? folderIdValue,
        selectedTabIndex,
      })
    );
  };

  const hanldeSearchButton = () => {};

  const RenderAdsComponent = useMemo(() => {
    const myItemsLength = boardAdsItems?.data?.length ?? 0;
    const hasMoreData = boardAdsItems?.total > myItemsLength;
    if (selectedFolder?.boards?.length === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Create your first board and Save Ads
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Add Multiple Boards in Folders and enjoy organizing Ads.
          </HeaderSubTitle>
        </ListContainer>
      );
    }

    if (!isLoading && myItemsLength === 0) {
      return (
        <>
          <ListContainer>
            <HeaderTitle textAlign="center">
              Your saved Ads will appear here
            </HeaderTitle>
            <HeaderSubTitle textAlign="center">
              Use our extension to save ads from platforms and grow
            </HeaderSubTitle>
          </ListContainer>
        </>
      );
    }

    return (
      <ListContainer>
        <CardListWrapper>
          {boardAdsItems?.data?.map(
            (item: SwipeFileListItems, index: number) => (
              <AdCard
                item={item}
                key={index}
                index={index}
                dataList={boardAdsItems?.data}
                locationPathname={""}
                isBoardAdsData={true}
              />
            )
          )}
          {(isLoading || hasMoreData) &&
            [...Array(4)]?.map((index) => (
              <AdCard item={{} as SwipeFileListItems} key={index} />
            ))}
        </CardListWrapper>
        <InfiniteScroll
          dataLength={myItemsLength}
          next={fetchMoreSwipeFileItem}
          hasMore={hasMoreData}
          loader={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }
          style={{ overflow: "hidden" }}
          key={myItemsLength}
        >
          <></>
        </InfiniteScroll>
      </ListContainer>
    );
  }, [boardAdsItems?.data, boardAdsItems?.total, isLoading, selectedTabIndex]);

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setSwipeFileTabIndex(newValue);
  };

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <SearchAndFilter
          handleFilter={fetchfilterSwipeFileItems}
          selectedBrands={selectedBrands}
          selectedPlatforms={selectedPlatforms}
          handleSortFilter={fetchSortingSwipeFileItems}
          selectedFilterOption={filterOption?.sortValue}
          renderBoardFilter={false}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          hanldeSearchButton={hanldeSearchButton}
          buttonsToShow={["users", "filter", "sort"]}
          filterCounts={boardAdsFilterCount}
          handleClearFilter={handleClearSwipeFileFilter}
          userId={userDetails?.user?._id}
          renderTagFilter={true}
          selectedTags={selectedTags}
        />
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              display: "flex",
              paddingX: "20px",
              gap: "5px",
              alignItems: "center !important",
              justifyContent: "center",
            }}
          >
            <FolderIconDark />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#414042" }}
            >
              Boards:
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ m: 0 }}
          />
          {selectedFolder?.boards?.length > 0 && (
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabs}
              variant="scrollable"
              scrollButtons={false}
              sx={{
                ".MuiTabs-flexContainer": { gap: "5rem" },
                minHeight: "3rem",
                "& .MuiTabs-scroller": {
                  overflowX: "auto",
                  scrollbarWidth: "none",
                  "-ms-overflow-style": "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-webkit-overflow-scrolling": "touch",
                },
              }}
            >
              {selectedFolder?.boards?.map((item: selectFolderBoardItem) => (
                <Tab
                  key={item?._id}
                  label={item?.name}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.colors.blue,
                      borderBottom: "1px solid",
                      fontWeight: "600",
                    },
                    color: theme.colors.gray1,
                    textTransform: "capitalize",
                    paddingBottom: "1rem",
                    minWidth: "fit-content",
                    fontSize: "1rem",
                    minHeight: "3rem",
                  }}
                />
              ))}
            </Tabs>
          )}
          {selectedFolder?.boards?.length === 0 && (
            <Typography
              sx={{ fontSize: "14px", color: "#414042", marginLeft: "8px" }}
            >
              No Boards Found
            </Typography>
          )}
        </Box>

        {RenderAdsComponent}
      </HeaderSection>
      <SaveAdPopover reFetchData={refetchSwipeFileItem} />
      <CreateBoardModal />
      <AddToBoardModal />
      <AddTagModal />
    </Container>
  );
};

export default MyFolder;
