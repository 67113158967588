import { call, put } from "redux-saga/effects";
import {
  FetchUserDetail,
  ResetPassword,
  UpdateUserDetails,
} from "../../services/AccountServices";
import { createSliceSaga } from "../saga-helper";
import { AxiosResponse } from "axios";
import { AccountReducerAction } from "./slice";
import { ToastTypes, notify } from "../../utils/helpers";
import { ResetPasswordProps } from "../../utils/propTypes";
import * as Sentry from "@sentry/react";
import { PlanListService } from "../../services/PlanService";
import { PlanType } from "./types";
import { monthlyPlanType, yearlyPlanType } from "../../utils/constants";
import { getCurrentPlanDetails } from "../../services/PaymentServices";

const saga = createSliceSaga({
  name: "account-saga",
  caseSagas: {
    *updateUserAccountDetails(action: any) {
      try {
        yield put(AccountReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(
          UpdateUserDetails,
          action.payload
        );

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          delete response?.data?.data?.password;
          yield put(AccountReducerAction.setUserDetails(response?.data?.data));
          yield put(AccountReducerAction.setIsLoading(false));
          action.payload.handleNavigate();
        } else {
          yield put(AccountReducerAction.setIsLoading(false));
          notify({ message: "Something went wrong", type: ToastTypes.ERROR });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchUserDetails(action: { payload: string; type: string }) {
      try {
        const response: AxiosResponse = yield call(
          FetchUserDetail,
          action.payload
        );

        if (response?.status === 200) {
          delete response?.data?.data?.password;
          yield put(AccountReducerAction.setUserDetails(response?.data?.data));
          yield put(
            AccountReducerAction.setUserPhoneNumber(
              response?.data?.data?.phoneNumber
                ? response?.data?.data?.phoneNumber
                : ""
            )
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *resetPassword(action: {
      payload: {
        id: string;
        body: ResetPasswordProps;
        handleResetState: Function;
        handleLogout: Function;
      };
      type: string;
    }) {
      const { id, body, handleResetState, handleLogout } = action.payload;
      try {
        const reqBody = {
          oldPassword: body.oldPassword,
          newPassword: body.newPassword,
        };
        const response: AxiosResponse = yield call(ResetPassword, {
          params: reqBody,
          id: id,
          handleResetState,
        });

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          yield put(AccountReducerAction.setOpenResetPasswordModal(false));
          handleResetState();
          handleLogout();
        }
      } catch (error) {
        handleResetState();
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *getPlanLists() {
      try {
        yield put(AccountReducerAction.setIsPlanLoading(true));
        const response: AxiosResponse = yield call(PlanListService);
        if (response?.status === 200) {
          const fetchActivePlanId = (
            planList: PlanType[] | undefined
          ): PlanType | {} => {
            if (!Array.isArray(planList)) {
              return {};
            }
            const plan = planList.find(
              (plan: PlanType) => plan?.currentlyActive
            );
            return plan ? plan : {};
          };
          const activeMonthlyPlan = fetchActivePlanId(
            response?.data?.data?.[monthlyPlanType]
          );
          const activeYearlyPlan = fetchActivePlanId(
            response?.data?.data?.[yearlyPlanType]
          );

          const plan = activeMonthlyPlan || activeYearlyPlan;

          yield put(AccountReducerAction.setPlanDetails(response?.data?.data));
          yield put(
            AccountReducerAction.setSelectPlanList(
              response?.data?.data?.["Monthly"]
            )
          );
          yield put(AccountReducerAction.setActivePlan(plan));
          yield put(AccountReducerAction.setIsPlanLoading(false));
        }
      } catch (error) {
        yield put(AccountReducerAction.setIsPlanLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *getCurrentPlan(action: {
      payload: {
        id: string;
      }
    }) {
      const { id } = action.payload;
      try {
        const response: AxiosResponse = yield call(getCurrentPlanDetails, {
          id: id,
        });

        if (response?.status === 200) {
          yield put(AccountReducerAction.setCurrentPlan(response?.data?.data));
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    }
  },
  sagaType: "takeEvery",
});

export const {
  actions: AccountSagaActions,
  saga: AccountSaga,
  name: AccountSagaName,
} = saga;
